
import { defineComponent } from "vue";
import {
  ApiHelper,
  boolToString,
  ButtonComponent,
  CircleLoader,
  dateFormat,
  Dictionary,
  DropdownComponent,
  NotificationsService,
  TABLE_ICONS,
} from "table";
import { APP_ICONS, DEFAULT_DROPDOWN_PROPS } from "@/common/consts";
import AnalyticalReportsReportParams from "@/modules/analytical-reports/components/AnalyticalReportsReportParams.vue";
import { AReportDto } from "@/modules/analytical-reports/common/types";
import ApiAReportsService from "@/modules/analytical-reports/services/ApiAReportsService/ApiAReportsService";
import AnalyticalReportsTable from "@/modules/analytical-reports/components/AnalyticalReportsTable.vue";
import AppUsersService from "@/services/AppUsersService/AppUsersService";
import { ApiVocUserDto } from "@/services/AppUsersService/types";
import {
  AReportSaveData,
  getStatusAReportName,
} from "@/modules/analytical-reports/services/ApiAReportsService/common/types/StatusAReport";
import AnalyticalReportSaveModal from "@/modules/analytical-reports/components/AnalyticalReportSaveModal.vue";
import AnalyticalReportPublishModal from "@/modules/analytical-reports/components/AnalyticalReportPublishModal.vue";
import AnalyticalReportsCreateReport from "@/modules/analytical-reports/components/AnalyticalReportsCreateReport.vue";
import { AReportsExtraInfo } from "@/modules/analytical-reports/services/ApiAReportsService/common/types/AReportsExtraInfo";
import { DropdownOption } from "table/dist/components/Dropdown/common/types/DropdownOption";

export default defineComponent({
  name: "AnalyticalReportsReport",
  components: {
    ButtonComponent,
    DropdownComponent,
    CircleLoader,
    AnalyticalReportsCreateReport,
    AnalyticalReportPublishModal,
    AnalyticalReportSaveModal,
    AnalyticalReportsTable,
    AnalyticalReportsReportParams,
  },
  setup() {
    return {
      TABLE_ICONS,
      APP_ICONS,
      dateFormat,
      getStatusAReportName,
      boolToString,
      DEFAULT_DROPDOWN_PROPS,
    };
  },
  data() {
    const list = [
      {
        title: "Просмотр отчёта",
        id: "view-report",
      },
      {
        title: "Параметры отчёта",
        id: "params-report",
      },
    ] as const;

    return {
      tabs: {
        list,
        active: list[0].id as "view-report" | string,
      },
      aReportDto: null as null | AReportDto,
      usersDict: null as Dictionary<ApiVocUserDto> | null,
      heights: {
        topDiv: 0,
      },
      open: {
        analyticalReportSaveModal: false,
        analyticalReportPublishModal: false,
        recalculate: false,
        create: false,
      },
      aReportsExtraInfo: null as AReportsExtraInfo | null,
    };
  },
  async created() {
    window.addEventListener("resize", this.resizeHandler);
    this.usersDict = await AppUsersService.getUsersDict();
    await ApiHelper.wrapNotifyError(async () => {
      this.aReportsExtraInfo = (await ApiAReportsService.getExtraInfo()).json;
    });
    await this.init();
  },
  mounted() {},
  updated() {
    this.resizeHandler();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {
    params() {
      this.init();
    },
  },
  computed: {
    heightBeforeContentTab(): number {
      return [this.heights.topDiv, 50, 40].reduce((value, currentValue) => {
        value += currentValue;
        return value;
      });
    },
    params(): {a_report_id: string} {
      return this.$route.params as {
        a_report_id: string;
      };
    },
    optionsExport(): DropdownOption[] {
      return [
        {
          title: "В .xlsx",
          onClick: () =>
            ApiAReportsService.exportToXlsx(this.params.a_report_id),
          props: {
            class: "button-blue",
            style: { width: "100%" },
          },
        },
      ];
    },

    keysTables(): string[] {
      return Object.keys(this.aReportDto?.repository?.tables || {});
    },

    joinTabNamesTables(): string {
      return this.keysTables
        .map((x) => this.aReportDto?.repository.tables[x].tab_name)
        .join(", ");
    },
  },
  methods: {
    async init() {
      this.aReportDto = (
        await ApiAReportsService.getAReport(this.params.a_report_id)
      ).json;
    },
    async onSave(formData: AReportSaveData) {
      if (!formData.areport_name) {
        NotificationsService.send({
          type: "error",
          title: "При сохранении произошла ошибка",
          text: "Заполните обязательные поля",
        });
        return;
      }

      await ApiHelper.wrapNotifyError(async () => {
        const result = await ApiAReportsService.saveAReport(
          this.aReportDto!.data.areport_id,
          formData,
        );
        this.aReportDto = result.json;
        this.open.analyticalReportSaveModal = false;
      });
    },

    async onPublish(formData: AReportSaveData) {
      if (!formData.areport_name) {
        NotificationsService.send({
          type: "error",
          title: "При публикации произошла ошибка",
          text: "Заполните обязательные поля",
        });
        return;
      }
      await ApiHelper.wrapNotifyError(async () => {
        const result = await ApiAReportsService.publishAReport(
          this.aReportDto!.data.areport_id,
          formData,
        );
        this.aReportDto = result.json;
        this.open.analyticalReportPublishModal = false;
      });
    },

    async onArchive() {
      await ApiHelper.wrapNotifyError(async () => {
        await ApiAReportsService.archiveAReport(
          this.aReportDto!.data.areport_id,
        );
        await this.$router.push("/analytical-reports");
      });
    },

    async onRemove() {
      await ApiHelper.wrapNotifyError(async () => {
        await ApiAReportsService.removeAReport(
          this.aReportDto!.data.areport_id,
        );
        window.close();
      });
    },

    async onRecalculate(aReportDto: AReportDto) {
      this.aReportDto = aReportDto;
      this.open.recalculate = false;
    },

    setHeights() {
      this.heights.topDiv = (this.$refs.topDiv as HTMLDivElement).clientHeight;
    },

    resizeHandler() {
      this.setHeights();
    },
  },
});
