import { ApiService, Dictionary, Logger, VOC_NAMES_DICT } from "table";
import {
  ApiUserAttrsDto,
  ApiVocUserDto,
} from "@/services/AppUsersService/types";

const USERS_DICT_KEY = "users_dict";

class AppUsersService {
  getQueryParams = ApiService.getQueryParams;

  getListByUsers() {
    return ApiService.getVocValues<ApiUserAttrsDto>(
      VOC_NAMES_DICT.users,
      undefined,
      true,
      undefined,
      undefined,
    );
  }

  async getUsersDict() {
    try {
      const users = await this.getListByUsers().then((x) => x.json.result);
      return ApiService.useCache(USERS_DICT_KEY, () =>
        users.reduce((usersDict, user) => {
          usersDict[user.record_id] = user;
          return usersDict;
        }, {} as Dictionary<ApiVocUserDto>),
      );
    } catch (ex: any) {
      Logger.error("Ошибка при загрузке словаря пользователей", ex);
      return {};
    }
  }

  getUsersDictSync(): Dictionary<ApiVocUserDto> | undefined {
    return ApiService.getCache(USERS_DICT_KEY)?.value;
  }

  async getUserById(user_id: number) {
    const usersDict = await this.getUsersDict();
    return usersDict[user_id];
  }
}

export default new AppUsersService();
