import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","flex-grow":"1","overflow":"auto"}
}
const _hoisted_2 = { class: "tabs-container" }
const _hoisted_3 = ["data-active", "onClick"]
const _hoisted_4 = { class: "default-page-padding-inline" }
const _hoisted_5 = {
  key: 0,
  class: "table-scroll-container"
}
const _hoisted_6 = { style: {"position":"fixed","right":"0px","bottom":"0px"} }
const _hoisted_7 = {
  key: 1,
  class: "app-h-s16-h24-w7-c333",
  style: {"margin":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_BlueHelpComponent = _resolveComponent("BlueHelpComponent")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!

  return (_ctx.tableData && _ctx.aReportDto)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData.tables, (_table, name) => {
            return (_openBlock(), _createElementBlock("div", {
              key: name,
              "data-active": _ctx.boolToString(_ctx.show.table === name),
              class: "tabs-container__tab",
              onClick: ($event: any) => (_ctx.show.table = name)
            }, _toDisplayString(_table.dto.tab_name), 9, _hoisted_3))
          }), 128))
        ], 512), [
          [_vShow, _ctx.keysTables.length > 1]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData.tables, (_table) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: _table.factory.options.tableName,
            style: {"display":"flex","flex-direction":"column","flex-grow":"1","overflow":"auto"}
          }, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.table?.tableDataSet.isTableMounted)
                ? (_openBlock(), _createBlock(_Teleport, {
                    key: 0,
                    disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                    to: '#' + _ctx.table.tableDataSet.idContainerDivUp
                  }, [
                    (_table.tableDataSet)
                      ? (_openBlock(), _createBlock(_component_TableActionsComponent, {
                          key: 0,
                          "table-data-set": _table.tableDataSet,
                          "changes-state": "hide",
                          "filter-state": "enable",
                          "row-append-state": "hide",
                          style: {"padding-bottom":"10px"}
                        }, null, 8, ["table-data-set"]))
                      : _createCommentVNode("", true),
                    (
              _table.tableDataSet && _table.tableDataSet.options.filtersSet.open
            )
                      ? (_openBlock(), _createBlock(_component_PanelFilters, {
                          key: 1,
                          "data-set": _table.tableDataSet.options.filtersSet,
                          "model-array": _table.tableDataSet.model,
                          "table-data-set": _table.tableDataSet,
                          types: _table.tableDataSet.types,
                          style: {"padding-bottom":"10px"}
                        }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
                      : _createCommentVNode("", true)
                  ], 8, ["disabled", "to"]))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.isTableEmpty(_table.tableDataSet))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_TableComponent, {
                    isShowInfo: false,
                    isViewColumnNumber: true,
                    "table-data-set": _table.tableDataSet,
                    "onUpdate:cells": ($event: any) => (_table.tableDataSet.updateCells($event)),
                    "onUpdate:cursor": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHelp($event)))
                  }, {
                    headers: _withCtx(({ classes, tableDataSet }) => [
                      _createVNode(_component_TableMultiHeaders, {
                        classes: classes,
                        headers: _table.factory.options.headers,
                        "left-col": _ctx.DEFAULT_LEFT_COLUMN_NAME,
                        model: _table.factory.modelDtoSort,
                        "table-data-set": tableDataSet,
                        onClickLeftCol: ($event: any) => (tableDataSet.selectedAll(this.$nextTick))
                      }, null, 8, ["classes", "headers", "left-col", "model", "table-data-set", "onClickLeftCol"])
                    ]),
                    _: 2
                  }, 1032, ["table-data-set", "onUpdate:cells"]),
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.helpHelper.help)
                      ? (_openBlock(), _createBlock(_component_BlueHelpComponent, {
                          key: 0,
                          style: {"margin":"24px","max-width":"30vw"},
                          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.helpHelper.setHelp(null)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.helpHelper.help), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, " Данные отсутствуют "))
          ])), [
            [_vShow, _table.factory.options.tableName === _ctx.show.table]
          ])
        }), 128))
      ]))
    : (_openBlock(), _createBlock(_component_CircleLoader, { key: 1 }))
}