import { ApiUserDto, ApiVocUserDto } from "@/services/AppUsersService/types";
import { Dictionary } from "table";
import AppUsersService from "@/services/AppUsersService/AppUsersService";

export class UserHelper {
  static getName(
    user: ApiVocUserDto | ApiUserDto | undefined | null,
    _default = "",
    user_id: string | number | undefined = undefined,
  ) {
    if (!user) {
      if (user_id) {
        return `user_id_${user_id}`;
      }

      return _default;
    }

    const vocUserDto = user as ApiVocUserDto;
    if (vocUserDto.attrs) {
      return vocUserDto.attrs.fio || vocUserDto.attrs.login;
    }

    const userDto = user as ApiUserDto;
    return userDto.fio || userDto.login || userDto.login;
  }

  static getNameOrId(
    usersDict: Dictionary<ApiVocUserDto>,
    user_id: string | number | undefined,
  ) {
    return UserHelper.getName(usersDict[user_id!], "", user_id);
  }

  static async getTableUsersDict() {
    const usersDict = await AppUsersService.getUsersDict();
    return Object.values(usersDict).reduce((dict, user) => {
      dict[user.record_id] = UserHelper.getNameOrId(usersDict, user.record_id);
      return dict;
    }, {} as Dictionary<string>);
  }
}
