
import { defineComponent, PropType } from "vue";
import { AReportDto } from "@/modules/analytical-reports/common/types";
import {
  AttrsComponent,
  TSelectComponent,
  Dictionary,
} from "table";
import { ApiVocUserDto } from "@/services/AppUsersService/types";
import { UserHelper } from "@/common/helpers/UserHelper";
import { VocRepositoryDto } from "table/dist/services/Api/types/VocRepositoryDto";
import ApiAReportsService from "@/modules/analytical-reports/services/ApiAReportsService/ApiAReportsService";

export default defineComponent({
  name: "AnalyticalReportsReportParams",
  props: {
    aReportDto: { type: Object as PropType<AReportDto>, required: true },
    usersDict: {
      type: Object as PropType<Dictionary<ApiVocUserDto>>,
      required: true,
    },
  },
  components: {
    TSelectComponent,
    AttrsComponent,
  },
  setup() {
    return {
      UserHelper,
    };
  },
  data() {
    return {
      vocRepositoryDto: null as VocRepositoryDto | null,
    };
  },
  async created() {
    if (this.aReportDto.data.areport_params) {
      this.vocRepositoryDto = await ApiAReportsService.getAReportParams(
        this.aReportDto.data.areport_params.areport_type,
      ).then((x) => x.json);
    }
  },
  computed: {
    areportCreatorName(): string | undefined {
      if (!this.aReportDto.data.areport_creator) return undefined;
      return UserHelper.getName(
        this.usersDict[this.aReportDto.data.areport_creator],
        "",
        this.aReportDto.data.areport_creator,
      );
    },
  },
});
